import './App.css';
import './Splash.css';
import { LinkButton } from './components/Button';
import { Section } from './components/Section';

export const Splash = () => {
  return (<Section className="darkGreen">
    <div class="fifty centerCenter splash">
      <h1 className='thin'>Hailey Cooknick</h1>
      <p>It's a pleasure to see you.</p>
      <div>
        <LinkButton href="#resume">Resume</LinkButton>
        <LinkButton href="#reel">Reel</LinkButton>
        <LinkButton href="https://drive.google.com/drive/folders/1KoJVWnfU6CIC2CXFYXB2M2GC42yPzRFE?usp=sharing">Vocal Clips</LinkButton>
      </div>
    </div>
    <div class="fifty centerCenter">
      <img alt="Hailey's headshot" className="headshot dropShadow" src='/assets/Hailey-Cooknick.jpeg' />
    </div>
  </Section>);
};